

.chat-widget-screen {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 422px;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 5;

}

.chat-widget-menu-bar {
    position: fixed;
    top: 0;
    width: 100%;
}

.sign-in-screen-height {
    height: 515px;
}

.chat-screen-height {
    height: 362px;
}

.chat-widget-screen .cs-message-list__scroll-wrapper {
    padding: 0px;
}

.chat-widget-screen .messages-container {
    height: 100%;
}

.chat-widget-screen .cs-message-list
  > .scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y {
  padding-bottom: 0px;
}

.chat-widget-btn-group {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.chat-widget-voice-btn-container{
    display: none;
    cursor: pointer;
}

.chat-widget-close-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-widget-close-btn{
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: #F3F3F3;
    border-radius: 50%;
    transition: all 300ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-widget-close-btn:hover{
    background-color: #e2e2e2;
    transition: all 300ms ease;
}

.voice-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.voice-menu-wrapper{
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;
    right: 56px;
    font-size: small;
    background-color: white;
    padding: 10px;
    border-radius: 13px;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
}

.voice-menu-option {
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    transition: all 300ms ease;
}

.voice-menu-option:hover{
    background-color: #F3F3F3;
    transition: all 300ms ease;
}

.voice-menu-option-selected{
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    font-weight: bold;
    transition: all 300ms ease;
}

.voice-menu-dot, .voice-menu-dot-selected {
    padding: 2.5px;
    height: 3px;
    width: 3px;
    border-radius: 50%;
}

.voice-menu-dot-selected{
    background-color: #000000;
}

#text-input-chat-widget-message .cs-message-input__content-editor-wrapper {
    border-radius: 20px;
    border: 2px solid #AAAAAA;
    color: #AAAAAA;
}

#text-input-chat-widget-message .cs-message-input__content-editor-wrapper:focus {
    border: 2px solid #565656;
}

#text-input-chat-widget-message .cs-message-input__content-editor[data-placeholder]:empty::before {
    color: #8B8B8B;
}

.text-input-chat-widget .cs-button--arrow{
    position: absolute;
    right: 16px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    background: #D9D9D9;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 300ms ease !important;
}

.text-input-chat-widget .cs-button--focussed, .chat-widget-screen .cs-typing-indicator__dot {
    background: linear-gradient(147.36deg, #FFD703 -4.06%, #FF766B 30.19%, #FF2FB8 73.9%, #147BB5 111.7%);
    transition: all 300ms ease !important;
}

.chat-widget-screen .cs-message--incoming .cs-message__content {
    background-color: transparent;
}

.chat-widget-screen .cs-message--outgoing .cs-message__content{
    background-color: #EEEEEE ;
    color: #000000
}

.chat-widget-screen .markdown.outgoing * {
    color: #000000;
}

.chat-widget .cs-message.cs-message--outgoing.cs-message--single .cs-message__content {
    border-radius: 20px !important;
}

.widget-sign-in-modal-wrapper {
    width: 100% !important;
    gap: 12px;
}

.widget-sign-in-header {
    padding: 0px 30px 0px 30px;
}

.widget-common-header {
    font-size: 22px !important;
    font-weight: 400 !important;
}

.widget-verify-placeholder-image {
    width: 48.03%;
}

@media screen and (max-width:768px) {
    .chat-widget-screen {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
        border-radius: 0px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        z-index: 5;
    }

    
    .chat-widget-content-window {
        height: 100%;
    }
}